<template>
  <div class="ConseillerManagerBeneficiaireDashboard" >
    <formTitleComponent />
      <div class="container">
        <h1 class="form_div_title text-center">Profil de {{beneficiaire.user.first_name}} {{beneficiaire.user.last_name}}</h1>
        <div class="form_div">
          <div class="form_content">

            <b-modal ref="globalAPILoadingErrorModal" id="globalAPILoadingErrorModal" hide-footer title="Erreur lors du chargement du profil">
              <div class="text-center">
                <p>Une erreur s'est produite lors du chargement des informations du profil,
                  veuillez vérifier les données renseignées ou nous contacter directement.
                </p>
                <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('globalAPILoadingErrorModal')">Fermer</b-button>
              </div>
            </b-modal>

            <loading
              :active.sync="isLoading"
              :can-cancel="false"
              :is-full-page="true"
              color="#BB3634">
            </loading>


            <b-tabs v-model="tabsIndex" content-class="mt-3" active-nav-item-class="font-weight-bold text-primary">

              <b-tab >
                <template slot="title">
                  <span class="h4">Général</span>
                </template>

                <h2>0. Informations à renseigner par le conseiller </h2>

                <div class="row mt-3">
                  <div class="col-12">
                    <b-form-group id="muna-form-group" label="Muna du bénéficiaire" label-for="muna-form-input"
                      label-cols-md="4" label-cols-lg="4" class="mandatory-input">
                      <b-form-input
                        id="muna-form-input"
                        type="text" maxlength="11"
                        v-model.trim.lazy="beneficiaireMuna"
                        @input="$v.beneficiaireMuna.$touch()"
                        :state="$v.beneficiaireMuna.$dirty ? !$v.beneficiaireMuna.$error : null"
                        placeholder="XXXXXXXX.XX">
                      </b-form-input>
                    </b-form-group>
                  </div>
                </div>

                <div class="row mt-1">
                  <div class="col-12">
                    <b-form-group id="memo-form-group" label="Mettre un mémo" label-for="memo-form-input"
                      label-cols-md="4" label-cols-lg="4">
                      <b-form-textarea
                        id="memo-form-input"
                        type="text" maxlength="599"
                        placeholder="Commentaire"
                        v-model.trim.lazy="beneficiaireMemo"
                        @input="$v.beneficiaireMemo.$touch()"
                        :state="$v.beneficiaireMemo.$dirty ? !$v.beneficiaireMemo.$error : null">
                      </b-form-textarea>
                    </b-form-group>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 text-center">
                    <b-button
                      pill block :variant="saveMunaMemoMessageButtonStyle" size="sm"
                      @click='saveMunaMemoMessage'
                      :disabled="$v.beneficiaireMuna.$invalid || $v.beneficiaireMemo.$invalid">
                        <b-spinner small label="save muna Spinner" v-if="saveMunaMemoMessageInProcess"></b-spinner> {{ saveMunaMemoMessageButtonText }}
                    </b-button>
                  </div>
                </div>

                <h2>1. Profil <v-icon name="check-circle" class="ml-1" style="color:green"/></h2>

                <div class="row">
                  <div class="col-md-6">
                    <span> Identifiant : </span> <strong>{{beneficiaire.user.username}}</strong>
                  </div>
                  <div class="col-md-6">
                    <span> Mot de passe : </span> <strong>**********</strong>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <span> Prénom : </span> <strong>{{beneficiaire.user.first_name}}</strong> ({{beneficiaire.other_first_names}})<br>
                  </div>
                  <div class="col-md-6">
                    <span> Nom : </span> <strong>{{beneficiaire.user.last_name}}</strong>
                  </div>
                </div>
                <div class="row">
                <div class="col-12">
                  <span> Genre : <strong>{{sexRender(beneficiaire.sex)}}</strong></span>
                </div>
              </div>
                <div class="row">
                  <div class="col-md-6">
                    <span> Date naissance : </span> <strong>{{beneficiaire.birthdate}}</strong> <br>
                  </div>
                  <div class="col-md-6">
                    <span> Situation : </span> <strong>{{situationRender(beneficiaire.situation)}}</strong>
                  </div>
                </div>
                <div v-if="beneficiaire.situation === 'MAR' || beneficiaire.situation === 'PACS' || beneficiaire.situation === 'VIEMAR'" class="row">
                  <div class="col-md-6">
                    <span> Prénom du conjoint : <strong>{{beneficiaire.conjoint_first_name}}</strong></span>
                  </div>
                  <div class="col-md-6">
                    <span> Nom du conjoint : <strong>{{beneficiaire.conjoint_last_name}}</strong></span> <br>
                  </div>
                </div>
                <div v-if="beneficiaire.situation === 'MAR' || beneficiaire.situation === 'PACS' || beneficiaire.situation === 'VIEMAR'" class="row">
                  <div class="col-12">
                    <span> Le conjoint est-il intermittent ? <strong>{{trueFalseRender(beneficiaire.is_conjoint_intermittent)}}</strong></span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <span>Numéro de téléphone : </span> <strong>{{beneficiaire.phone_number}}</strong> <br>
                  </div>
                  <div class="col-md-6">
                    <span> Autre numéro : </span> <strong>{{beneficiaire.other_phone_number}}</strong>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <span>Adresse : </span> <strong>{{beneficiaire.address}}, {{beneficiaire.code_postal}} {{beneficiaire.city}}</strong> <br>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <span>Activité : </span> <strong>{{activiteRender(beneficiaire.activite)}}</strong> <br>
                  </div>
                  <div class="col-md-6">
                    <span> Métier : </span> <strong>{{beneficiaire.metier}}</strong>
                  </div>
                </div>

                  <!-- CHILDREN -->
                  <h2> 2. Enfants renseignés
                    <v-icon v-if="beneficiaire.children.length == 0" name="times-circle" class="ml-1" style="color:red"/>
                    <v-icon v-if="beneficiaire.children.length > 0" name="check-circle" class="ml-1" style="color:green"/>
                  </h2>

                  <b-table
                    responsive class="align-middle text-center mt-4"
                    headVariant="light"
                    :fields="childrenRenderFields"
                    :items="childrenRender"
                    >
                    <template v-slot:cell(birthdate)="data">
                      {{$dayjs(data.item.birthdate).format('D MMM YYYY')}}
                    </template>
                    <template v-slot:cell(age)="data">
                      <span v-if="data.item.age >= 4" class="text-danger">{{data.item.age}} ans <small> (non éligibile) </small> </span>
                      <span v-else class="text-success">{{data.item.age}} ans <small> (éligible)</small></span>
                    </template>
                  </b-table>

              </b-tab>


              <b-tab >
                <template slot="title">
                  <span class="h4">Accords de principe</span>
                </template>

                <h2>{{currentYear}} -
                  <span v-if="!currentAccord">Aucun accord de principe en cours<v-icon name="times-circle" class="ml-2" style="color:red"/></span>
                  <span v-else-if="currentAccord.state == '1CREATED'" class="text-info">
                    Accord de principe en cours de création, demande de validation à faire
                    <v-icon class="ml-2" name="edit" scale="1.4"/>
                  </span>
                  <span v-else-if="currentAccord.state == '2ONGOING'" class="text-secondary">
                    En cours de validation par le conseiller Audiens
                    <v-icon class="ml-2" name="dot-circle" scale="1.3"/>
                  </span>
                  <span v-else-if="currentAccord.state == '3INCOMPLET'" class="text-danger">
                    Dossier à compléter, le bénéficiaire doit corriger ses informations et réactualiser sa demande
                    <v-icon class="ml-2" name="times-circle" scale="1.4"/>
                  </span>
                  <span v-else-if="currentAccord.state == '3IMPOTINCOMPLET'" class="text-danger">
                    Dossier à compléter, le bénéficiaire doit ajouter des documents en plus de son avis d'imposition
                    <v-icon class="ml-2" name="times-circle" scale="1.4"/>
                  </span>
                  <span v-else-if="currentAccord.state == '4CREFUSED'" class="text-danger">
                    Refusé par le conseiller Audiens, en attente de confirmation par le manager
                    <v-icon class="ml-2" name="times-circle" scale="1.4"/>
                  </span>
                  <span v-else-if="currentAccord.state == '5CVALIDATED'" class="text-success">
                    Validé par le conseiller Audiens, en attente de confirmation par le manager
                    <v-icon class="ml-2" name="check-circle" scale="1.4"/>
                  </span>
                  <span v-else-if="currentAccord.state == '6MREFUSED'" class="text-danger">
                    Refusé par le manager
                    <v-icon class="ml-2" name="times-circle" scale="1"/>
                  </span>
                  <span v-else-if="currentAccord.state == '7MVALIDATED'" class="text-success">
                    Validé par le manager
                    <v-icon class="ml-2" name="check-circle" scale="1.4"/>
                  </span>
                </h2>

                <b-button v-if="currentAccord" @click="goToAccordPage(currentAccord.id)" pill block variant="outline-info" class="mt-4">
                  Accéder à la demande d'accord
                </b-button>

                <hr>


                <h2>Accords de principes pour les années passées</h2>

                <div v-if="lastAccords.length === 0">
                  <p>
                    Aucun accord pour les années passées
                  </p>
                </div>
                <div v-else>
                  <div v-for="accord in lastAccords" v-bind:key="accord.id" class="mt-3">
                    <strong>{{accord.year}} -
                      <span v-if="accord.state == '1CREATED'" class="text-info">
                        Accord de principe créé, demande de validation à réaliser<v-icon class="ml-1" name="edit" scale="1"/>
                      </span>
                      <span v-else-if="accord.state == '2ONGOING'" class="text-secondary">
                        En cours de validation par le conseiller Audiens <v-icon class="ml-1" name="dot-circle" scale="1"/>
                      </span>
                      <span v-else-if="accord.state == '3INCOMPLET'" class="text-danger">
                        Dossier à compléter, le bénéficiaire doit corriger ses informations et réactualiser sa demande <v-icon class="ml-1" name="times-circle" scale="1"/>
                      </span>
                      <span v-else-if="accord.state == '3IMPOTINCOMPLET'" class="text-danger">
                        Dossier à compléter, le bénéficiaire doit ajouter ses documents en plus de son avis d'imposition  <v-icon class="ml-1" name="times-circle" scale="1"/>
                      </span>
                      <span v-else-if="accord.state == '4CREFUSED'" class="text-danger">
                        Refusé par le conseiller, en attente de confirmtion par le manager  <v-icon class="ml-1" name="times-circle" scale="1"/>
                      </span>
                      <span v-else-if="accord.state == '5CVALIDATED'" class="text-success">
                        Validé par le conseiller, en attente de confirmtion par le manager  <v-icon class="ml-1" name="check-circle" scale="1"/>
                      </span>
                      <span v-else-if="accord.state == '6MREFUSED'" class="text-danger">
                        Refusé par le manager<v-icon class="ml-1" name="times-circle" scale="1"/>
                      </span>
                      <span v-else-if="accord.state == '7MVALIDATED'" class="text-success">
                        Validé par le manager <v-icon class="ml-1" name="check-circle" scale="1"/>
                      </span>
                    </strong>
                    <b-button @click="goToAccordPage(accord.id)" pill block variant="outline-secondary" class="mt-3" size="sm">
                      Accéder à la demande d'accord
                    </b-button>
                  </div>
                </div>

              </b-tab>


              <b-tab >
                <template slot="title">
                  <span class="h4">Demandes de remboursement</span>
                </template>

                <h2>Ses remboursements</h2>
                <p class="mt-3">
                  <span> Montant total remboursé en {{currentYear}} : </span>
                  <strong>{{formatThousandSeparatorNumber(parseFloat(totalMontantCurrentYear).toFixed(2))}} €</strong> (frais de garde) /
                  <strong>{{formatThousandSeparatorNumber(parseFloat(totalMontantFraisDeplacementCurrentYear).toFixed(2))}} €</strong> (frais supplémentaires)
                </p>
                <p>
                  <span> Montant total remboursé en {{currentYear-1}} : </span>
                  <strong>{{formatThousandSeparatorNumber(parseFloat(totalMontantLastYear).toFixed(2))}} €</strong> (frais de garde) /
                  <strong>{{formatThousandSeparatorNumber(parseFloat(totalMontantFraisDeplacementLastYear).toFixed(2))}} €</strong> (frais supplémentaires)
                </p>
                <b-table
                  responsive class="align-middle text-center mt-4 cursor-table"
                  headVariant="light" hover
                  :fields="remboursementsRenderFields"
                  :items="beneficiaire.remboursements"
                  @row-clicked="goToRemboursementPage"
                  >
                  <template v-slot:cell(month)="data">
                    {{monthRender(data.item.month)}}
                  </template>
                  <template v-slot:cell(state)="data">
                    {{remboursementStateRender(data.item.state)}}
                  </template>
                  <template v-slot:cell(montant)="data">
                    <span v-if="data.item.state == '7MVALIDATED' || data.item.state == '8PAIEMENT'">
                      {{data.item.montant}} €
                      <span v-if="data.item.montant_frais_deplacement > 0">
                        <br>
                        <small>{{data.item.montant_frais_deplacement}} € de frais supplémentaires</small>
                      </span>
                    </span>
                  </template>
                </b-table>
              </b-tab>
            </b-tabs>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { APIConseillerConnected } from '@/api/APIConseillerConnected'
import renderMixins from '@/mixins/renderMixins'
import { dateOperations } from '@/mixins/utilMixins'
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { isMuna } from '@/validators/validators'

import formTitleComponent from '@/components/formTitleComponent'


import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';


const apiConseillerConnected = new APIConseillerConnected()

export default {
  name: 'ConseillerManagerBeneficiaireDashboard',
  components: {
    formTitleComponent,
    Loading,
  },
  mixins: [
    renderMixins,
    validationMixin,
    dateOperations
  ],
  data: function () {
    const now = new Date()
    const currentYear = now.getFullYear()
    return {

      tabsIndex: 0,

      // ------- BENEFICIAIRE --------
      beneficiaire: {
        user: {
          first_name: '',
          last_name: '',
          username: '',
        },
        activite: '',
        metier: '',
        address: '',
        birthdate: '',
        city: '',
        code_postal: '',
        other_first_names: '',
        muna: '',
        memo_message: '',
        phone_number: '',
        other_phone_number: '',
        situation: '',
        conjoint_first_name: '',
        conjoint_last_name: '',
        is_conjoint_intermittent: false,
        refused_message: '',
        children: [],
        accords: [],
        remboursements: []
      },
      beneficiaireLoading: false,

      // ------ CHILDREN ----------
      childrenRenderFields: [
        { key: "first_name", label: "Prénom"},
        { key: "last_name", label: "Nom"},
        { key: "birthdate", label: "Date de naissance"},
        { key: "age", label: "Age"},
      ],

      // ------  ACCORD -----------
      currentYear: currentYear,
      currentAccord : null,
      lastAccords: [],


      totalMontantCurrentYear: 0,
      totalMontantFraisDeplacementCurrentYear: 0,
      totalMontantFraisDeplacementLastYear: 0,
      totalMontantLastYear: 0,

      remboursementsRenderFields: [
        { key: "month", label: "Mois concerné", tdClass: 'ui-helper-center', sortable: true},
        { key: "year", label: "Année concernée", tdClass: 'ui-helper-center', sortable: true},
        { key: "state", label: "Etat", tdClass: 'ui-helper-center' },
        { key: "montant", label: "Montant remboursé", tdClass: 'ui-helper-center' },
      ],

      // --------MUNA MEMO  ---------------
      beneficiaireMuna: '',
      beneficiaireMemo: '',
      saveMunaMemoMessageButtonText: "Enregistrer le muna et votre mémo",
      saveMunaMemoMessageButtonStyle: "outline-info",
      saveMunaMemoMessageInProcess: false,

    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.beneficiaireLoading) {
        return true
      }
      return false
    },
    childrenRender () {
      if (this.beneficiaire.children.length == 0) {
        return []
      }
      else {
        var final_array = []
        for (var i = 0; i < this.beneficiaire.children.length; i++) {
          if (this.beneficiaire.children[i].is_active) {
            final_array.push(
              {
                'id': this.beneficiaire.children[i].id,
                'first_name': this.beneficiaire.children[i].first_name,
                'last_name': this.beneficiaire.children[i].last_name,
                'birthdate': this.beneficiaire.children[i].birthdate,
                'age': this.calculateAge(this.beneficiaire.children[i].birthdate),
              }
            )
          }
        }
        return final_array
      }
    }
  },
  created: function() {
    this.$dayjs.locale('fr')
    this.getBeneficiaire()
  },
  validations: {
    beneficiaireMuna: {
      required,
      minLength: minLength(11),
      maxLength: maxLength(11),
      validMuna(value) {
        return isMuna(value)
      }
    },
    beneficiaireMemo: {
      minLength: minLength(2),
      maxLength: maxLength(599),
    },
  },
  watch: {
    beneficiaire: function (newBeneficiaire) {
      // ---- ACCORD
      for (var i = 0; i < newBeneficiaire['accords'].length; i++) {
        const temp_accord = newBeneficiaire['accords'][i]
        // Accord
        if (temp_accord['year'] === this.currentYear) {
          this.currentAccord = temp_accord
        }
        else {
          this.lastAccords.push(temp_accord)
        }
      }

      // Calculate the montant total alloué
      this.totalMontantCurrentYear = 0
      this.totalMontantLastYear = 0

      for (var j = 0; j < newBeneficiaire.remboursements.length; j++) {
        // Accord
        if (newBeneficiaire.remboursements[j].year === this.currentYear && (newBeneficiaire.remboursements[j].state === '7MVALIDATED' || newBeneficiaire.remboursements[j].state === '8PAIEMENT')) {
          this.totalMontantCurrentYear = this.totalMontantCurrentYear  + parseFloat(newBeneficiaire.remboursements[j].montant)
          this.totalMontantFraisDeplacementCurrentYear = this.totalMontantFraisDeplacementCurrentYear  + parseFloat(newBeneficiaire.remboursements[j].montant_frais_deplacement)
        }

        else if (newBeneficiaire.remboursements[j].year === this.currentYear - 1 && (newBeneficiaire.remboursements[j].state === '7MVALIDATED' || newBeneficiaire.remboursements[j].state === '8PAIEMENT')){
          this.totalMontantLastYear = this.totalMontantLastYear  + parseFloat(newBeneficiaire.remboursements[j].montant)
          this.totalMontantFraisDeplacementLastYear = this.totalMontantFraisDeplacementLastYear  + parseFloat(newBeneficiaire.remboursements[j].montant_frais_deplacement)
        }
      }
    },
  },

  methods: {
    // ------------ GLOBAL METHOD ------------------
    userGroupContains(groupsList) {
      var userGroups = this.$store.getters.getUserGroups
      var userGroupsArray = []
      var is_contained = false
      if (userGroups) {
        try {
          var userGroupsJson = JSON.parse(userGroups)
          for (var i = 0; i < userGroupsJson.length; i++) {
            userGroupsArray.push(
              userGroupsJson[i]['name']
            )
          }
        }
        catch(error) {
          this.$store.dispatch('AUTH_LOGOUT').then(() => {
            this.$router.push('/login')
            this.$bvToast.toast(`Votre session a expiré, veuillez vous reconnecter`, {
              title: 'Session expirée',
              autoHideDelay: 7000,
              appendToast: false
            })
          })
          return is_contained
        }
      }

      for (var j = 0; j < groupsList.length; j++) {
        if (userGroupsArray.indexOf(groupsList[j]) > -1) {
          is_contained = true
        }
      }
      return is_contained
    },

    // ---------------------- BENEFICIAIRES -----------------------
    getBeneficiaire () {
      this.beneficiaireLoading = true
      apiConseillerConnected.getBeneficiaire(this.token, this.$route.params.beneficiaire_id)
        .then((result) => {
          this.beneficiaire = result.data
          this.beneficiaireMuna = result.data.muna
          this.beneficiaireMemo = result.data.memo_message
        })
        .catch((error) => {
          this.$refs.globalAPILoadingErrorModal.show()
          throw new Error('ConseillerManagerBeneficiaireDashboard getBeneficiaire API Error : ' + String(error))
        })
        .finally(() => {
          this.beneficiaireLoading = false
        })
    },

    // ------------ MODIFICATION ---------------

    saveMunaMemoMessage () {
      this.saveMunaMemoMessageButtonText = "Enregistrement en cours"
      this.saveMunaMemoMessageButtonStyle = "secondary"
      this.saveMunaMemoMessageInProcess = true
      apiConseillerConnected.saveMunaMemoMessage(
        this.token, this.$route.params.beneficiaire_id, this.beneficiaireMuna, this.beneficiaireMemo)
      .then(() => {
        this.getBeneficiaire()
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('ConseillerManagerBeneficiaireDashboard saveMunaMemoMessage API Error : ' + String(error))
      })
      .finally(() => {
        this.saveMunaMemoMessageButtonText = "Enregistrer le muna et votre mémo"
        this.saveMunaMemoMessageButtonStyle = "outline-info"
        this.saveMunaMemoMessageInProcess = false
      })
    },


    // --------------- ROUTER FUNCTION -----------------------
    goToAccordPage(accord_id) {
      if (this.userGroupContains(['MANAGER'])) {
        this.$router.push('/manager-dashboard/accord/' + accord_id)
      }
      else if (this.userGroupContains(['CONSEILLER'])) {
        this.$router.push('/conseiller-dashboard/accord/' + accord_id)
      }
      else {
        this.$router.push('/login')
      }
    },
    goToRemboursementPage(item) {
      if (this.userGroupContains(['MANAGER'])) {
        this.$router.push('/manager-dashboard/remboursement/' + item.id)
      }
      else if (this.userGroupContains(['CONSEILLER'])) {
        this.$router.push('/conseiller-dashboard/remboursement/' + item.id)
      }
      else {
        this.$router.push('/login')
      }
    },
  }
}
</script>
<style>
.option-button {
    height:100%;
}

.media-object {
    height: 100px;
}
</style>
